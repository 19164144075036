import { toastController, ToastOptions } from '@ionic/vue';
import debounce from 'lodash-es/debounce';
import { DebouncedFunc } from 'lodash-es';
import { store } from '@/store';
import { SET_TOAST } from '@/store/actions.type';

export const useToast = () => {
  const open: DebouncedFunc<(options: ToastOptions) => Promise<void>> = debounce(async (options: ToastOptions) => {
    const newToast = await toastController.create({
      color: 'dark',
      ...options
    });
    store.state.toast?.dismiss();
    await newToast.present();
    newToast.onDidDismiss().then(() => {
      store.dispatch(SET_TOAST, null);
    });
    await store.dispatch(SET_TOAST, newToast);
  }, 150);

  const close = () => store.state.toast?.dismiss();

  return {
    open,
    close
  };
};
