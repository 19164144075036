
import { IonPage, IonContent, IonButton, IonFooter, IonIcon, IonLoading, onIonViewWillEnter } from '@ionic/vue';
import { ref } from 'vue';
import bigPicture from '../../resources/icons/bigPicture.svg';
import { useRoute, useRouter } from 'vue-router';
import { LoginService } from '@/services';
import { integer } from 'aws-sdk/clients/cloudfront';
import { useToast } from '@/composables/useToast';

export default {
  name: 'CheckYourEmail',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonFooter,
    IonIcon,
    IonLoading
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loginService = new LoginService();
    const email = ref<string>('');
    const emailLastSent = ref<integer>(0);
    const toast = useToast();
    const isLoading = ref<boolean>(false);

    onIonViewWillEnter(async () => {
      await setEmailFromRouteParams();
    });

    const setEmailFromRouteParams = async () => {
      if (!route.params.email) {
        await router.push({ name: 'login' });
      }
      email.value = route.params.email as string;
    };

    const sendMagicLink = async () => {
      if (Date.now() - emailLastSent.value < 60000) {
        await toast.open({
          message: `Please try again in ${Math.floor((60000 - (Date.now() - emailLastSent.value)) / 1000)} seconds`,
          duration: 5000
        });
        return;
      }

      if (isLoading.value) {
        return;
      }
      isLoading.value = true;

      try {
        await loginService.validateEmail({
          email: email.value
        });
        await toast.open({
          message: 'Email sent',
          buttons: ['Close']
        });
        emailLastSent.value = Date.now();
      } catch (e) {
        await toast.open({
          message: 'The email failed to send. Please check your email and try again.',
          buttons: ['Close'],
          color: 'danger'
        });
        await router.push({ name: 'login' });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      bigPicture,
      setEmailFromRouteParams,
      sendMagicLink,
      email,
      isLoading
    };
  }
};
